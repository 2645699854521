'use strict'

const _ = require('lodash')
const React = require('react')
const createReactElement = require('../utils/createReactElement')
const createReactClass = require('create-react-class')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

const wrapperFactory = React.createFactory(createReactClass({
    displayName: 'overflowWrapper',
    render() {
        return createReactElement('div', this.props)
    }
}))

module.exports = {
    propTypes: {
        id: santaTypesDefinitions.Component.id,
        isResponsive: santaTypesDefinitions.RendererModel.isResponsive,
        registerToInnerScroll: santaTypesDefinitions.Scrollable.registerToInnerScroll,
        unregisterInnerScroll: santaTypesDefinitions.Scrollable.unregisterInnerScroll,
        layoutContainerClassName: santaTypesDefinitions.Layout.layoutContainerClassName,
        layoutContainerOverflowWrapperClassName: santaTypesDefinitions.Layout.layoutContainerOverflowWrapperClassName,
        shouldRenderOverflowWrapper: santaTypesDefinitions.Layout.shouldRenderOverflowWrapper
    },

    componentDidMount() {
        if (this.props.shouldRenderOverflowWrapper) {
            this.props.registerToInnerScroll(this.props.id, '[id$=overflowWrapper]')
        }
    },

    componentDidUpdate(prevProps) {
        if (this.props.shouldRenderOverflowWrapper !== prevProps.shouldRenderOverflowWrapper) {
            if (this.props.shouldRenderOverflowWrapper) {
                this.props.registerToInnerScroll(this.props.id, '[id$=overflowWrapper]')
            } else {
                this.props.unregisterInnerScroll(this.props.id)
            }
        }
    },

    componentWillUnmount() {
        if (this.props.shouldRenderOverflowWrapper) {
            this.props.unregisterInnerScroll(this.props.id)
        }
    },

    getInlineContent(props) {
        return _.assign({}, props,
            {
                className: this.props.layoutContainerClassName
            },
            this.props.shouldRenderOverflowWrapper && {
                wrap: [wrapperFactory, {
                    id: `${this.props.id}overflowWrapper`,
                    className: this.props.layoutContainerOverflowWrapperClassName
                }]
            }
        )
    }
}
